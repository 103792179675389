/* Core CSS required for Ionic components to work properly */
@import '~@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
@import '~@ionic/react/css/normalize.css';
@import '~@ionic/react/css/structure.css';
@import '~@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
@import '~@ionic/react/css/padding.css';
@import '~@ionic/react/css/float-elements.css';
@import '~@ionic/react/css/text-alignment.css';
@import '~@ionic/react/css/text-transformation.css';
@import '~@ionic/react/css/flex-utils.css';
@import '~@ionic/react/css/display.css';
/* Theme variables */
@import './theme/variables.scss';
@import '~react-modern-calendar-datepicker/lib/DatePicker.css';

.badge {
  border-radius: 50px !important;
}
body {
  background-color: whitesmoke !important;
  direction: rtl;
  letter-spacing: 0px;
}

input[role='combobox'] {
  height: 50px;
  border-radius: 5px;
  font-size: 13px;
  color: #1c1d3e;
  background: #fafaff;
  border: 1px solid transparent;
  width: 100%;
}

.ion-no-border {
  border: none;
}

.featured-item {
  min-height: 100%;
}

.featured-item:after {
  background: transparent !important;
}

.divider {
  margin: 0 10px;
}
.page {
  background: white;
  width: 100%;
  min-height: 400px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 -2px 8px #00000066;
}

footer {
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 5px;
}

.searchbar-search-icon {
  left: 5px !important;
  right: unset !important;
}

.searchbar-input {
  padding-right: 5px !important;
  text-align-last: right !important;
}
.searchbar-input-ios {
  padding-right: 5px !important;
  text-align-last: right !important;
}
.searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
  padding-right: 5px !important;
  text-align-last: right !important;
  -ms-text-align-last: right !important;
  padding-left: unset !important;
  padding-inline-start: 5px !important;
  padding-inline-end: unset !important;
  &::placeholder {
    text-align: right;
    padding-right: 5px !important;
  }
}
.custom-btn-one {
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 5px;
  border: 1px #ffffff solid !important;
  color: #ffffff;
}
.page-container {
  padding: 15px 10px;
  border-radius: 6px 6px 0 0;
  position: relative;
  top: -100px;
}

.patterned-content {
  height: 200px;
  background-size: 100%;
}

.mobile-only #ayria-header {
  padding: 10px 20px;
}
#ayria-header {
  position: fixed;
  z-index: 999;
  width: 100%;
  box-shadow: 0 0 5px #6b6b6bbf;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1500px;
  }
}

.dir-ltr,
.ltr {
  direction: ltr;
}

.statistics {
  border: 3px solid;
  border-radius: 8px;
  font-size: 13px;
  color: #8e8e93;
  letter-spacing: 0.64px;
  text-align: right;
  padding: 20px 10px !important;
  margin-right: 20px !important;
  margin-left: 5px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.statisticsSummery {
  max-height: 63px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 3px solid;
  border-radius: 8px;
  font-size: 13px;
  color: #8e8e93;
  letter-spacing: 0.64px;
  text-align: right;
  line-height: 4em;
}

.statistic-item {
  //line-height: 4em;
  font-size: 16px;
}

.temp_acceptor-list {
  border-radius: 2px;
  border-top: 1px solid #979797;
  margin: 5px;
}

.dashboard-tab {
  border: 1px solid rgb(0, 185, 199);
  border-radius: 2px;
  padding: 5px;
  text-align: center;
  margin: 2px;
}

.copy-right {
  position: fixed;
  bottom: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

::-webkit-input-placeholder {
  text-align: right;
}

input:-moz-placeholder {
  text-align: right;
}

.marginHorizontalAuto {
  margin-left: 10vw;
  margin-right: 10vw;
}

.uni-ltr {
  unicode-bidi: embed;
  direction: ltr;
}

a[disabled],
span[disabled] {
  pointer-events: none;
}

.main-logo {
  max-width: 200px;
  height: auto;
}

.gray-effect {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.span-click-title {
  position: relative;
}
.span-click-title:active:after {
  content: attr(title);
}

.font-roboto,
.font-roboto > * {
  font-family: 'Roboto' !important;
}

.print-only {
  display: none;
}

#factors-modal {
  .modal-wrapper {
    height: 100vh;
  }
}

input::placeholder {
  text-align: right;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  .printable,
  .printable * {
    visibility: visible;
  }
  .non-printable,
  .non-printable * {
    display: none;
  }
  .print-only {
    display: block;
  }
  .page-break {
    display: block;
    break-before: page;
    page-break-before: always;
  }
}
