#dashboard-acceptor-score {
  .featured-item {
    min-height: 50%;
  }
}

#apRequests.box-header-success {
  border: 2px solid var(--ion-color-success-shade);
  color: var(--ion-color-success-shade);
}

.responsive-image {
  max-width: 100%;
  height: auto;
}
.onlineshop-section {
  width: auto;
  //background: #095f3c;
  //color: #fff;
  margin-left: 5px;
  margin-right: 2px;
  text-align: center;
  //height: 20px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.v-button-holder {
  display: 'flex';
  flex-flow: 'column nowrap';
  align-items: 'center';
}

//NEW PANEL
.customCard {
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 15px !important;
  //padding: 20px;
  &.white {
    background-color: #ffffff;
  }
  &.purple {
    background-color: #0019e9;
  }
}
.customCardTitleSeparator {
  border: 0 solid #e5e5ea;
  border-bottom-width: 1px;
}

.cursor-pointer {
  cursor: pointer;
}

.withBorder {
  border-style: solid;
  border-color: #c7c7cc;
  border-width: 0;
  &.rightBorder {
    border-right-width: 1px;
  }
}

.circleIcon {
  border-radius: 50px;
  color: white;
  padding: 10px;

  &.green {
    background-color: #28cb7d;
  }
  &.red {
    background-color: #e22f4f;
  }
  &.orange {
    background-color: #f6a62d;
  }
  &.blue {
    background-color: #3a4cf0;
  }
}

.walletFuncButton {
  padding: 8px 20px;
  background-color: rgba(255, 255, 255, 0.3);
  color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  border-color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  span {
    padding-right: 13px;
  }

  &.viewWalletID {
    border-color: #cbc7c7;
    color: #000000;
    background-color: #ffffff;
    span {
      padding-right: 0;
    }
  }
}

.walletFuncButtonBorderRight {
  border: 0 solid #ffffff;
  border-right-width: 1px;
}

.customTableTwo {
  width: 100%;
  border-collapse: collapse;
  //table-layout: fixed;

  tr {
    cursor: pointer;
  }

  th {
    background-color: #e5e5ea33;
    color: #7b7b7b;
  }

  th,
  td {
    padding: 10px;
  }

  text-align: center;
}

.paymentServiceItem {
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  border: #c7c7cc solid 1px;
}

.disableIonBackground {
  .searchbar-input {
    background-color: transparent !important;
  }
}

.customSideBar {
  z-index: 999;
  background-color: #003899;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
}
